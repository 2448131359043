import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import HubspotForm from "react-hubspot-form";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import * as style from "../styles/one-pager.module.scss";
import PostTagDate from "../components/PostTagDate";

import prevIcon from "../images/icons/leftArrow.svg";
import nextIcon from "../images/icons/rightArrow.svg";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const posts = data.allGhostPost.edges;

    let prev;
    let next;

    const PrevNextPost = () => {
        posts.forEach((x, index) => {
            if (post.id === x.node.id) {
                prev = index === 0 ? false : posts[index - 1].node;
                next =
                    index === posts.length - 1 ? false : posts[index + 1].node;
            }
        });
        return prev || next ? (
            <div className={`${style.bgColorOffwhite}`}>
                <div className={`${style.prevNextPost} clarsynt-container`}>
                    <div>
                        <div>
                            {prev && (
                                <a
                                    href={prev.fields.path}
                                    className={`d-flex align-items-center`}
                                >
                                    <img src={prevIcon} alt="Previous icon" />
                                    <div className={`ml-2`}>{prev.title}</div>
                                </a>
                            )}
                        </div>
                        <div>
                            {next && (
                                <a
                                    href={next.fields.path}
                                    className={`d-flex align-items-center`}
                                >
                                    <div className={`mr-2`}>{next.title}</div>
                                    <img src={nextIcon} alt="Next icon" />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    };

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout
                post
                headerImage={post.feature_image}
                headerText={<div>{post.title}</div>}
            >
                <article>
                    <section className="post-full-content py-5 px-4">
                        <PostTagDate post={post} />

                        {/* The main post content */}
                        <section
                            className="load-external-scripts pt-4"
                            dangerouslySetInnerHTML={{
                                __html: post.childHtmlRehype.html,
                            }}
                        />
                    </section>
                </article>
                <PrevNextPost />

                <div className={`${style.clarsyntSubscriptionForm}`}>
                    <div className={`clarsynt-container`}>
                        <h2>Insights in your Inbox</h2>
                        <p>
                            Sign up to get the latest insights sent directly to
                            your inbox.
                        </p>
                        <div
                            className={`${style.clarsyntSubscriptionFormWrapper}`}
                        >
                            <HubspotForm
                                portalId="6139600"
                                formId="35770bae-32cf-40a1-9950-ae6ae6e46d38"
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.shape({
            edges: PropTypes.shape({
                forEach: PropTypes.func,
                node: PropTypes.node,
                length: PropTypes.number,
            }),
        }),
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            childHtmlRehype: PropTypes.object,
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
            childHtmlRehype {
                html
            }
            localFeatureImage {
                childImageSharp {
                    gatsbyImageData(layout: FIXED)
                }
            }
        }
        allGhostPost(
            sort: { fields: published_at, order: DESC }
            filter: { slug: { ne: "data-schema" } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
